import * as React from "react"
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../Container"
import { Link } from "gatsby";
import Cookiebar from "../cookieBar";
import ChatFooterBlue from "../../images/chat-footer-blue.svg"

const Footer = () =>{

    const data = useStaticQuery(graphql`
    query FooterLogoQuery{
        wp {
            generalSettings {
                title
              }
              siteLogo {
                altText
                mediaItemUrl
            }

              acfOptionsFooter {
                footer {
                  footerLogo {
                    mediaItemUrl
                  }
                  footerlogolink
                  footerTekst
                }
              }
            }
        allWpMenu {
            edges {
              node {
                locations
                menuItems {
                  nodes {
                    label
                    uri
                    childItems {
                      nodes {
                        uri
                        label
                        url
                      }
                    }
                  }
                }
              }
            }
          }
    }
    `) 

    const footerPrimary = data.allWpMenu.edges.filter((e)=> e.node.locations.includes("FOOTER"))
    const footerMenu = footerPrimary[0].node.menuItems.nodes.map((item, i)=>{
        
        if(item.childItems.nodes.length > 0){
          return(
              <ul className="lg:mb-0 mt-5 z-10 md:w-1/2">
                <li className="text-md lg:text-xl font-bold mb-5">{item.label}</li>
                {
                  item.childItems.nodes.map((c, i)=>{
                    const count = i + 1
                    return(
                      <li className={`${i > 0 && count !==  item.childItems.nodes.length ? 'my-3' : count === item.childItems.nodes.length ? 'mt-3' : 'mb-3'} hover:text-primary transition-all duration-300 ease-in-out`}><Link target={c.uri.startsWith('/wp-content/') ? '_blank' : ''} to={c.uri.startsWith('/wp-content/') ? `${process.env.GATSBY_ENDPOINT}${c.uri}` : c.uri } className="py-3">{c.label}</Link></li>
                    )
                  })
                }
              </ul>
          )
        }

    })

    const footerDisclaimer = data.allWpMenu.edges.filter((e)=> e.node.locations.includes("FOOTER_PRIVACY"))
    const disclaimerMenu = footerDisclaimer[0].node.menuItems.nodes.map((item, i)=>{

        return(
            <li className={`${i > 0 ? 'ml-8' : ''} text-sm`}><Link to={item.uri} className="py-3">{item.label}</Link></li>
        )
    })
    
    return(
        <footer className={`footerClass`}>
            <Cookiebar />
            <Container styles={`grid lg:grid-cols-4 grid-cols-1 lg:gap-5 py-16`}>
                <div className="col-span-2">
                    <Link to="/"><img src={data.wp.siteLogo.mediaItemUrl} width={265}></img></Link>
                    <div className="mt-12 leading-loose text-md lg:text-lg" dangerouslySetInnerHTML={{__html: data.wp.acfOptionsFooter.footer.footerTekst}}></div>
                </div>
                <div className="col-span-2 flex lg:flex-row md:flex-row flex-col lg:justify-around md:justify-start justify-between relative">
                    {footerMenu}
                    <img src={ChatFooterBlue} className="scale-110 xl:-top-[10%] lg:top-0 absolute lg:block hidden"></img>
                </div>
            </Container>
            <div className="bg-[#f8f8f8] py-5">
              <Container styles={`grid lg:grid-cols-2 grid-cols-1`}>
                <div className="flex lg:flex-row flex-col justify-start lg:items-center">
                    <p className="text-sm flex lg:flex-row flex-col"><span className="lg:mr-2 mr-0">Copyright &copy;{new Date().getFullYear()} {data.wp.generalSettings.title}</span> <span className="lg:block hidden">|</span> <span className="lg:ml-2 ml-0 mt-4 lg:mt-0">Webdesign: <Link to='https://hq-online.nl/' target={'_blank'}>HQ Online</Link></span></p>
                </div>
                <div className="flex flex-row lg:justify-end lg:mt-0 mt-5">
                  <Link to={data.wp.acfOptionsFooter.footer.footerlogolink} target={'_blank'}>
                    <img src={data.wp.acfOptionsFooter.footer.footerLogo.mediaItemUrl} width={200}></img>
                  </Link>
                </div>
              </Container>
            </div>
        </footer>
    )
}

export default Footer;