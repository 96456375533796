import { Link } from "gatsby"
import * as React from "react"
import { useEffect } from "react"
import { useState } from "react"
import Container from "../Container"

const Navigation = ({data, width, handler, state}) => {
    const [activeSub, setActiveSub] = useState()
    const chevron = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2"><path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>
    const plus = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2"><path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" /></svg>
    const left = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2"><path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" /></svg>
    const arrow = <svg className="group-hover:stroke-[#000] group-hover:translate-x-3 stroke-white transition-all duration-300 ease-in-out" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.7182 8.93005L48.5 25.7118L31.7182 42.4936M1.5 25.7118H48.03" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
    
    

    useEffect(()=>{
        if(state == false){
            setActiveSub()
        }
    },[state])

    console.log(data)

    const menu = data.menuItems.nodes.map((m, i)=>{
        
        const menuItem = 
            m.parentId == null ? 
                <li className={`${i + 1 === (data.menuItems.nodes.length - m.childItems.nodes.length) ? '' : 'lg:mr-8 mr-0'} menu-item flex items-center`}>
                    {m.childItems.nodes.length > 0 && width < 1024 ?
                        <div className="w-full flex flex-row items-center justify-between">
                            <Link to={m.uri} onClick={handler}>{m.label}</Link>
                            <span onClick={()=>{setActiveSub(i)}}>{plus}</span>
                        </div>
                    :
                    <Link to={m.uri} target={`{m.uri.startsWith('https://') ? '_blank' : ''}`} className={`flex flex-row items-center ${m.cssClasses}`} onClick={handler}>
                        <span>{m.label}</span>
                        { m.childItems.nodes.length > 0 ?
                            chevron
                        : null}
                    </Link>
                    }
                    { m.childItems.nodes.length > 0 && width > 1024 ? 
                        <div className="absolute subitem-list w-full left-0">
                            <ul className={`p-8 mt-4 bg-white grid grid-cols-3 gap-8`}>
                                {m.childItems.nodes.map((c, i)=>{
                                    const icon = c.connectedNode?.node.oplossingen?.icon?.mediaItemUrl
                                    return(
                                        <li className={`h-full`}>
                                            <Link to={c.uri}>
                                                <div className={`${i + 1 == m.childItems.nodes.length ? 'bg-primary group hover:bg-[#f8f8f8]' : 'bg-[#f8f8f8] hover:bg-secondary'}  h-full p-5 transition-all duration-300 ease-in-out`}>
                                                    {icon !== undefined ? <img src={icon}></img> : arrow } 
                                                    <h3 className={`text-xl font-bold my-4 ${i + 1 == m.childItems.nodes.length ? 'text-white group-hover:text-text transition-all duration-300 ease-in-out' : 'text-text'}`}>{c.label}</h3>
                                                    <div dangerouslySetInnerHTML={{__html: c.description}} className={`${i + 1 == m.childItems.nodes.length ? 'text-white group-hover:text-text transition-all duration-300 ease-in-out' : 'text-text'}`}></div>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    : m.childItems.nodes.length > 0 && width < 1024 ?
                        <div className={`subitem-list z-20 w-full h-screen absolute top-0 bg-white transition-all duration-300 py-28 ${activeSub == i ? 'left-0' : '-left-full'}`}>
                            <div className="bg-primary flex flex-row items-center p-4 text-lg text-white" onClick={()=>{setActiveSub()}}>{left} Terug</div>
                            <ul className={`px-5 text-lg grid grid-cols-1 gap-6 navigation mt-6 `}>
                                {m.childItems.nodes.map((c, i)=>{
                                    return(
                                        <li className={`h-full`}><Link to={c.uri} onClick={handler}>{c.label}</Link></li>
                                    )
                                })}
                            </ul>
                        </div>
                    : null}
                </li> 
            : null

        return(
            menuItem
        )
    })

    if(width > 1024){
        return(
            <ul className="flex flex-row justify-end navigation relative w-9/12">
                {menu}
            </ul>
        )
    }else{
        return(
            <div className={`z-10 w-full h-screen absolute top-0 bg-white transition-all duration-300 ${state ? 'left-0' : '-left-full'}`}>
                <ul className="py-28 px-5 text-lg grid grid-cols-1 gap-6 navigation">
                    {menu}
                </ul>
            </div>
        )
    }
}

export default Navigation