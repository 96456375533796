import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import * as React from "react"
import { createGlobalStyle } from "styled-components"
import { AnimatePresence } from "framer-motion"
import Footer from "../Footer"
import Header from "../Header"
import Helmet from "react-helmet"

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
  query colorQuery{
    wp {
      acfOptionsTypografie {
        typografie {
          headerfonts{
            navigatiefont
          }
          standaardfonts{
            h1font
            h2font
            h3font
            textfont
          }
        }
      }
      acfOptionsKleuren {
        kleuren {
          header {
            background
            textcolor
          }
          footer {
            background
            textcolor
          }
          textcolors {
            h1color
            h2color
            h3color
            textcolor
          }
          kleurpalet {
            accentcolor
            bgcolor
            primarycolor
            secondarycolor
          }
        }
      }
    }
    }`)

  // Palet
  const primary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.primarycolor
  const secondary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.secondarycolor
  const accent = data.wp.acfOptionsKleuren.kleuren.kleurpalet.accentcolor
  const text = data.wp.acfOptionsKleuren.kleuren.textcolors.textcolor
  const background = data.wp.acfOptionsKleuren.kleuren.kleurpalet.bgcolor

  // Header
  const headerBackground = data.wp.acfOptionsKleuren.kleuren.header.background
  const headerText = data.wp.acfOptionsKleuren.kleuren.header.textcolor

  // Footer
  const footerBackground = data.wp.acfOptionsKleuren.kleuren.footer.background
  const footerText = data.wp.acfOptionsKleuren.kleuren.footer.textcolor

  // Headings
  const h1 = data.wp.acfOptionsKleuren.kleuren.textcolors.h1color
  const h2 = data.wp.acfOptionsKleuren.kleuren.textcolors.h2color
  const h3 = data.wp.acfOptionsKleuren.kleuren.textcolors.h3color

  // Fonts
  const h1font = data.wp.acfOptionsTypografie.typografie.standaardfonts.h1font
  const h2font = data.wp.acfOptionsTypografie.typografie.standaardfonts.h2font
  const h3font = data.wp.acfOptionsTypografie.typografie.standaardfonts.h3font
  const textfont = data.wp.acfOptionsTypografie.typografie.standaardfonts.textfont

  // Navigatie
  const navigationFont = data.wp.acfOptionsTypografie.typografie.headerfonts.navigatiefont

  const GlobalStyles = createGlobalStyle`

    :root {
      --primary-color: ${primary};
      --secondary-color: ${secondary};
      --accent-color: ${accent};
      --text-color: ${text};
      --bg-color: ${background};
      --text-font: '${textfont.replaceAll('+', ' ')}';
      --h1-font: '${h1font.replaceAll('+', ' ')}';
      --h2-font: '${h2font.replaceAll('+', ' ')}';
      --h3-font: '${h3font.replaceAll('+', ' ')}';
      --nav-font: '${navigationFont?.replaceAll('+', ' ')}';
    }
    body{
      font-family: var(--text-font);
    }
    h1{
      color: ${h1};
      font-family: var(--h1-font);
    }
    h2{
      color: ${h2};
      font-family: var(--h2-font);
    }
    h3{
      color: ${h3};
      font-family: var(--h3-font);
    }
    .headerClass{
      background-color: ${headerBackground};
      color: ${headerText};
    }
    .footerClass{
      background-color: ${footerBackground};
      color: ${footerText};
    }
    .navigation{
      font-family: var(--nav-font);
    }
  `

  return (
      <>
        <GlobalStyles />
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href={`https://fonts.googleapis.com/css2?family=${h1font}:wght@100;200;300;400;500;600;700;800;900&display=swap`} rel="stylesheet" />
          <link href={`https://fonts.googleapis.com/css2?family=${h2font}:wght@100;200;300;400;500;600;700;800;900&display=swap`} rel="stylesheet" />
          <link href={`https://fonts.googleapis.com/css2?family=${h3font}:wght@100;200;300;400;500;600;700;800;900&display=swap`} rel="stylesheet" />
          <link href={`https://fonts.googleapis.com/css2?family=${textfont}:wght@100;200;300;400;500;600;700;800;900&display=swap`} rel="stylesheet" />
          <link href={`https://fonts.googleapis.com/css2?family=${navigationFont}:wght@100;200;300;400;500;600;700;800;900&display=swap`} rel="stylesheet" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css" integrity="sha512-SzlrxWUlpfuzQ+pcUCosxcglQRNAq/DZjVsC0lE40xsADsfeQoEypE+enwcOiGjk/bSuGGKHEyjSoQ1zVisanQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        </Helmet>
        <Header></Header>
          <main className="bg-background">
            <AnimatePresence exitBeforeEnter>
              {children}
            </AnimatePresence>
          </main>
        <Footer></Footer>
      </>
  )
}

export default Layout
