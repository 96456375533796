import React from "react";

const Container = ({children, styles, inlineCss}) => {
    console.log(inlineCss)
    return(
        <div className={`container mx-auto 2xl:max-w-screen-xl px-5 ${styles}`} style={{...inlineCss}}>
            {children}
        </div>
    )
}

export default Container;